<template>
  <div>
    <v-app-bar id="home-app-bar" app color="white" elevation="1" height="80">
      <base-img
        :src="require('@/assets/logo.svg')"
        class="mr-3"
        contain
        max-width="52"
        width="100%"
        v-on:click="$router.push('/')"
      />

      <!--<base-img
        :src="require('@/assets/zero-logo-light.svg')"
        contain
        max-width="128"
        width="100%"
      />-->
      <div
        class="display-2"
        v-on:click="$router.push('/')"
        style="cursor: pointer"
      >
        SYRF
      </div>

      <v-spacer />

      <div>
        <v-tabs class="hidden-sm-and-down" optional>
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            <template v-if="name == 'Home'">
              <v-icon color="#416fad">mdi-home</v-icon>
            </template>
            <template v-else>
              {{ $ml.get(name) }}
            </template>
          </v-tab>
          <v-tab offset-y @click="gotoTelegram">
            <v-icon color="#416fad">mdi-send</v-icon>
          </v-tab>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-tab
                dark
                v-bind="attrs"
                v-on="on"
                :id="$ml.current"
                class="ml-4"
              />
            </template>
            <v-list>
              <v-list-item
                v-for="lang in $ml.list"
                :key="lang"
                link
                @click="$ml.change(lang)"
              >
                <v-tab style="width: 70px; height: 40px" :id="lang" />{{ lang }}
              </v-list-item>
            </v-list>
          </v-menu>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
    </v-app-bar>

    <home-drawer v-model="drawer" :items="items" />
  </div>
</template>

<script>
export default {
  name: "HomeAppBar",

  components: {
    HomeDrawer: () => import("./Drawer"),
  },

  data: () => ({
    drawer: null,
    items: [
      "Home",
      "About",
      //'Contact',
      "Statistic",
      //'Pro',
      "Account",
    ],
  }),
  methods: {
    gotoTelegram: function () {
      window.open("https://t.me/syrf_official_channel");
    },
  },
};
</script>

<style lang="sass">
#home-app-bar
    .v-tabs-slider
        max-width: 24px
        margin: 0 auto

    .v-tab
    &::before
        display: none

#en
    background-image: url(../../assets/en.svg)
    background-size: 50% 100%
    width: 30px
#ru
    background-image: url(../../assets/ru.svg)
    width: 30px
    background-size: 50% 100%
</style>
